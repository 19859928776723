
import SideBar from '@/components/sidebar/SideBar.vue';
import TitleBar from '@/components/titlebar/TitleBar.vue';
import Vue from 'vue';

export default Vue.extend({
    name: 'App',
    components: {
        SideBar,
        TitleBar,
    },
    computed: {
        showNavbar() {
            return this.$route.meta?.showNavbar;
        },
    },
    beforeDestroy() {
        this.$sessionManager.destroy();
    },
});
