
import ExplainableTextInput from '@/components/shared/ExplainableTextInput.vue';
import FeatureToggle from '@/components/shared/FeatureToggle.vue';
import {
    convertFromCompoundDivisionCode,
    groupMapOnCompoundDivisionCode,
    phoneNumberValidator,
    withDefault,
} from '@/components/shared/utils';
import { AdminSendMethodTab, ClinicalDocumentType } from '@/models';
import { assignFaxNumber, removeFaxNumber } from '@/shared/mutations';
import { FaxNumber, Mutation } from 'generated/graphql/graphql';
import _ from 'underscore';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'AssignFaxNumberDialog',
    components: {
        ExplainableTextInput,
        FeatureToggle,
    },
    props: {
        disabled: Boolean,
        selectedPayerCodes: { type: Array<string>, required: true },
        selectedClinicalSummaryFaxNumbers: { type: Array<FaxNumber>, required: true },
        selectedAppealFaxNumbers: { type: Array<FaxNumber>, required: true },
    },
    data: () => ({
        autofocus: false,
        hover: false,
        selectedTabIndex: 0,
        isDialogOpen: false,
        payerCodes: [] as string[],
        faxNumbers: [] as FaxNumber[],
        newFaxNumber: null as string | null,
        newFaxNumberCount: 0,
        faxNumbersToDelete: [] as string[],
        selectedPrimaryFaxNumber: undefined as FaxNumber | undefined,
        ClinicalDocumentType,
    }),
    computed: {
        getClinicalDocumentType(): ClinicalDocumentType {
            return AdminSendMethodTab[this.selectedTabIndex];
        },
    },
    watch: {
        selectedPayerCodes() {
            this.populatePayerCodes();
        },
        selectedClinicalSummaryFaxNumbers() {
            if (!this.disabled) {
                this.populateFaxNumbers();
            }
        },
        selectedPrimaryFaxNumber() {
            if (this.selectedPrimaryFaxNumber === undefined) {
                this.selectedPrimaryFaxNumber = this.faxNumbers.find((number) => number.order === 1) ?? this.faxNumbers.at(0);
            }
        },
        selectedTabIndex(index) {
            this.populateFaxNumbers(AdminSendMethodTab[index]);
        },
    },
    created() {
        this.populateFaxNumbers();
        this.populatePayerCodes();
    },
    methods: {
        withDefault,
        phoneNumberValidator,
        convertFromCompoundDivisionCode,
        groupMapOnCompoundDivisionCode,
        isValidFaxNumber(faxNumber: string) {
            return phoneNumberValidator(faxNumber) === true && faxNumber !== this.newFaxNumber;
        },
        displayDeleteButton(faxNumberToDelete: FaxNumber): boolean {
            //cannot delete the primary selected fax number
            return faxNumberToDelete !== this.selectedPrimaryFaxNumber;
        },
        close(): void {
            //reset state
            this.populateFaxNumbers();
            this.selectedPrimaryFaxNumber = undefined;
            this.autofocus = false;
            this.isDialogOpen = false;
            this.selectedTabIndex = 0;
        },
        async removeFaxNumberAssignment() {
            const removalPromises = groupMapOnCompoundDivisionCode(this.payerCodes, (division, payerCodes) => {
                return this.$apollo.mutate<Mutation>({
                    mutation: removeFaxNumber,
                    variables: {
                        input: {
                            division,
                            faxNumbers: this.faxNumbersToDelete,
                            payerCodes,
                            type: this.getClinicalDocumentType,
                        },
                    },
                });
            });

            await Promise.all(removalPromises);
        },
        async saveAssignment() {
            const faxNumbersAreValid = Array.from(this.faxNumbers).every((item) => phoneNumberValidator(item.faxNumber) === true);
            if (!faxNumbersAreValid) {
                this.autofocus = true; //autofocus on invalid field
                this.$toast.error('Oops! Something is not right');
                return;
            }
            if (this.faxNumbersToDelete.length > 0) {
                this.removeFaxNumberAssignment();
            }
            //save primary fax number and create orders list
            const orders = this.faxNumbers.flatMap((val) => {
                if (val === this.selectedPrimaryFaxNumber) {
                    val.order = 1;
                    return 1;
                }
                val.order = null;
                return null;
            });
            //assign fax numbers
            const faxNumbersToSave = this.faxNumbers.map((number) => number.faxNumber);
            const type = this.getClinicalDocumentType;

            // promise is effectively void, but is an Apollo FetchResult
            const faxNumberAssignmentsByDivision: Promise<any>[] = groupMapOnCompoundDivisionCode(
                this.payerCodes,
                (division, payerCodes) => {
                    return this.$apollo.mutate<Mutation>({
                        mutation: assignFaxNumber,
                        variables: {
                            input: {
                                faxNumbers: faxNumbersToSave,
                                division,
                                payerCodes,
                                orders,
                                type,
                            },
                        },
                    });
                }
            );

            // wait for all promises to resolve before updating the UI to reflect the resolution
            await Promise.all(faxNumberAssignmentsByDivision);
            this.$emit('mutate', this.faxNumbers, type);
            this.$toast.success('Send Method successfully saved!');
            this.close();
        },
        populateFaxNumbers(type: ClinicalDocumentType = ClinicalDocumentType.CLINICAL_SUMMARY) {
            const isAppealFax = type === ClinicalDocumentType.APPEAL;
            const fax = isAppealFax ? this.selectedAppealFaxNumbers : this.selectedClinicalSummaryFaxNumbers;
            this.faxNumbers = [];
            if (fax?.length === 0) {
                this.newFaxNumberCount--;
                this.faxNumbers.push({ id: this.newFaxNumberCount.toString(), faxNumber: this.newFaxNumber } as FaxNumber);
                this.selectedPrimaryFaxNumber = Array.from(this.faxNumbers.values()).at(0);
            } else {
                fax.forEach((faxNumber) => {
                    const faxNumberClone = _.clone(faxNumber);
                    if (faxNumber.order === 1) {
                        this.selectedPrimaryFaxNumber = faxNumberClone;
                        this.faxNumbers.unshift(faxNumberClone); //primary is always displayed first
                    } else {
                        this.faxNumbers.push(faxNumberClone);
                    }
                });
            }
        },
        populatePayerCodes() {
            this.payerCodes = [];
            this.selectedPayerCodes.forEach((payerCode) => this.payerCodes.push(payerCode));
        },
        async deleteFaxNumber(faxNumber: FaxNumber) {
            if (this.selectedClinicalSummaryFaxNumbers.find((number) => number.faxNumber === faxNumber.faxNumber)) {
                //only perform delete mutation on numbers that were previously saved
                this.faxNumbersToDelete.push(faxNumber.faxNumber);
            }
            const delIndex = this.faxNumbers.findIndex((number) => number === faxNumber);
            this.$delete(this.faxNumbers, delIndex);
        },
        addNew() {
            //all existing fields must be populated and valid to add new
            const faxNumbersAreValid = Array.from(this.faxNumbers).every((item) => phoneNumberValidator(item.faxNumber) === true);
            if (faxNumbersAreValid) {
                this.newFaxNumberCount--;
                this.faxNumbers.push({ id: this.newFaxNumberCount.toString(), faxNumber: this.newFaxNumber } as FaxNumber);
            }
        },
    },
});
