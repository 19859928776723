
import DatePicker from '@/components/shared/DatePicker.vue';
import FeatureToggle from '@/components/shared/FeatureToggle.vue';
import MultiSelectFilter from '@/components/shared/MultiSelectFilter.vue';
import { coerceToId, formatFacilityLocation, formatPersonName } from '@/components/shared/utils';
import { updateUserPreferences } from '@/shared/mutations';
import { getWorklistFilters } from '@/shared/queries';
import { useWorklistFiltersStore } from '@/stores/WorklistFiltersStore';
import {
    AdmitStatus,
    Facility,
    HospitalService,
    Location,
    Mutation,
    Payer,
    PayerCategory,
    Query,
    User,
    VisitFiltersInput,
} from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'WorklistFilters',
    components: {
        DatePicker,
        MultiSelectFilter,
        FeatureToggle,
    },
    data: () => ({
        worklistFiltersStore: useWorklistFiltersStore(),
        admitStatusItems: null as AdmitStatus[] | null,
        predictedAdmitStatusItems: null as AdmitStatus[] | null,
        facilityItems: null as Facility[] | null,
        locationItems: null as Location[] | null,
        serviceItems: null as HospitalService[] | null,
        payerItems: null as Payer[] | null,
        payerCategoryItems: null as PayerCategory[] | null,
        umNurseOwnerItems: null as User[] | null,
    }),
    async created() {
        await this.loadItems();
        this.worklistFiltersStore.$subscribe(() => {
            if (this.worklistFiltersStore.autoSave) {
                this.saveUserPreferences({
                    fromAdmitDate: this.worklistFiltersStore.selectedFromDate,
                    toAdmitDate: this.worklistFiltersStore.selectedToDate,
                    admitStatusCodes: this.worklistFiltersStore.selectedAdmitStatusCodes,
                    predictedAdmitStatusCodes: this.worklistFiltersStore.selectedPredictedAdmitStatusCodes,
                    facilityCodes: this.worklistFiltersStore.selectedFacilityCodes,
                    locationCodes: this.worklistFiltersStore.selectedLocationCodes,
                    hospitalServiceCodes: this.worklistFiltersStore.selectedHospitalServiceCodes,
                    payerCodes: this.worklistFiltersStore.selectedPayerCodes,
                    payerCategoryCodes: this.worklistFiltersStore.selectedPayerCategoryCodes,
                    umNurseOwnerIds: this.worklistFiltersStore.selectedUmNurseOwnerIds,
                    losExceedsGmlos: this.worklistFiltersStore.losGreaterThanGmlos,
                    activeDenials: this.worklistFiltersStore.activeDenials,
                });
            } else {
                this.saveUserPreferences(null);
            }
        });
    },
    methods: {
        formatPersonName,
        formatFacilityLocation,
        coerceToId,
        async loadItems(): Promise<void> {
            // Note results are cached by the Apollo Client
            const response = await this.$apollo.query<Query>({
                query: getWorklistFilters,
            });

            this.admitStatusItems = response.data.admitStatuses;
            this.predictedAdmitStatusItems = response.data.predictedAdmitStatuses;
            this.facilityItems = response.data.facilities;
            this.locationItems = response.data.locations;
            this.serviceItems = response.data.hospitalServices;
            this.payerItems = response.data.payers;
            this.payerCategoryItems = response.data.payerCategories;
            this.umNurseOwnerItems = response.data.usersByRole;
        },
        saveUserPreferences(filters: VisitFiltersInput | null): void {
            this.$apollo.mutate<Mutation>({
                mutation: updateUserPreferences,
                variables: {
                    input: {
                        worklistFilters: filters,
                    },
                },
            });
        },
    },
});
