
import Vue from 'vue';

export default Vue.extend({
    name: 'MultiSelectFilter',
    props: {
        name: String,
        value: Array,
        items: Array,
        itemText: [String, Array, Function],
        itemValue: [String, Array, Function],
    },
    data: () => ({
        searchInput: null,
    }),
    computed: {
        selectedItems: {
            get(): unknown {
                return this.value;
            },
            set(value: unknown) {
                this.$emit('input', value);
            },
        },
    },
});
