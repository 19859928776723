
import Loadable from '@/components/shared/Loadable.vue';
import VisitConditionDetailsView from '@/components/visit/conditions/VisitConditionDetailsView.vue';
import VisitLengthOfTreatmentChart from '@/components/visit/length-of-treatment/VisitLengthOfTreatmentChart.vue';
import {
    VisitConditionDataSection,
    VisitConditionDetails,
    VisitConditionMedicationDataItem,
    VisitConditionTab,
    VisitLengthOfTreatmentStatus,
} from '@/models';
import { getVisitConditions, getVisitConditionsLengthOfTreatment, getVisitConditionSupportingData } from '@/shared/queries';
import { LabVitalsResultsTable, Query, VisitCondition, VisitConditionLengthOfTreatment } from 'generated/graphql/graphql';
import moment from 'moment';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'VisitLengthOfTreatment',
    components: {
        Loadable,
        VisitLengthOfTreatmentChart,
        VisitConditionDetailsView,
    },
    data: () => ({
        loading: true,
        disableFilter: false,
        conditions: [] as VisitCondition[],
        filteredConditions: [] as VisitCondition[],
        dateCount: 0,
        chartStartDate: moment(),
        chartEndDate: moment(),
        selectedFilter: VisitLengthOfTreatmentStatus.ALL,
        isDetailViewOpen: false,
        selectedCondition: undefined as VisitCondition | undefined,
        selectedSection: undefined as VisitConditionDataSection | undefined,
        selectedTab: '',
        filterOptions: Object.values(VisitLengthOfTreatmentStatus),
    }),
    async created() {
        await this.loadConditions(parseInt(this.$route.params.id));
        this.setChartDates();
        this.loading = false;
    },
    methods: {
        async loadConditions(visitId: number): Promise<void> {
            const [conditionsResponse, lotResponse] = await Promise.all([
                this.$apollo.query<Query>({
                    query: getVisitConditions,
                    variables: {
                        visitId: visitId,
                    },
                    fetchPolicy: 'no-cache',
                }),
                this.$apollo.query<Query>({
                    query: getVisitConditionsLengthOfTreatment,
                    variables: {
                        visitId: visitId,
                    },
                    fetchPolicy: 'no-cache',
                }),
            ]);

            this.conditions = conditionsResponse.data.visitConditions.items;
            const lotMap = lotResponse.data.visitConditionLengthOfTreatment.reduce((acc, curr) => {
                acc[curr.visitConditionId] = curr;
                return acc;
            }, {} as Record<string | number, VisitConditionLengthOfTreatment>);
            this.conditions.forEach((condition) => {
                condition.lengthOfTreatment = lotMap[condition.id];
            });
            this.filteredConditions = this.conditions ?? [];
            this.disableFilter = (this.filteredConditions.length ?? 0) === 0;
        },
        setChartDates() {
            const defaultStart = moment(
                this.filteredConditions[0]?.lengthOfTreatment?.startDate ? this.filteredConditions[0].lengthOfTreatment.startDate : ''
            );

            let { start, end } = this.filteredConditions.reduce(
                (acc: { start: moment.Moment; end: moment.Moment }, condition: VisitCondition) => {
                    if (
                        condition?.lengthOfTreatment?.startDate &&
                        (!acc.start.isValid() || moment(condition.lengthOfTreatment?.startDate).isBefore(acc.start))
                    ) {
                        acc.start = moment(condition.lengthOfTreatment.startDate).subtract(1, 'day');
                    }

                    const conditionEnd = this.getConditionEnd(condition);
                    acc.end = conditionEnd.isAfter(acc.end) ? conditionEnd : acc.end;

                    return acc;
                },
                {
                    start: defaultStart,
                    end: this.getConditionEnd(this.filteredConditions[0]),
                } as { start: moment.Moment; end: moment.Moment }
            );

            // Adjust the start and end to give the chart some buffer days on either side of the data
            start.subtract(1, 'day');
            end.add(1, 'day');
            const days = end.startOf('day').diff(start.startOf('day'), 'days');
            this.chartStartDate = start;
            this.chartEndDate = end;
            this.dateCount = days;
        },
        updateFilter() {
            switch (this.selectedFilter) {
                case VisitLengthOfTreatmentStatus.ALL:
                    this.filteredConditions = this.conditions;
                    break;
                case VisitLengthOfTreatmentStatus.ACTIVE:
                    this.filteredConditions = this.conditions.filter((x) => !x.lengthOfTreatment?.endDate);
                    break;
                case VisitLengthOfTreatmentStatus.COMPLETED:
                    this.filteredConditions = this.conditions.filter((x) => !!x.lengthOfTreatment?.endDate);
            }

            this.setChartDates();
        },
        async openDetails(condition: VisitCondition): Promise<void> {
            await this.loadSupportingData(condition);
            this.isDetailViewOpen = true;
            this.selectedCondition = condition;
        },
        closeDetails(): void {
            this.isDetailViewOpen = false;
        },
        async loadSupportingData(condition: VisitConditionDetails): Promise<void> {
            const response = await this.$apollo.query<Query>({
                query: getVisitConditionSupportingData,
                variables: {
                    id: parseInt(condition.id),
                },
                fetchPolicy: 'no-cache',
            });

            const supportingData = response.data.visitCondition?.supportingData;
            const labsSection = this.parseSupportingData(
                VisitConditionTab.LABS,
                supportingData?.labVitalsResultsTable ?? ({} as LabVitalsResultsTable)
            );
            condition.supportingDataSections = [
                labsSection,
                this.parseSupportingData(VisitConditionTab.MEDICATIONS, {
                    medicationOrders: supportingData?.medicationOrders ?? [],
                    medicationAdministrations: supportingData?.medicationAdministrations ?? [],
                } as VisitConditionMedicationDataItem),
                this.parseSupportingData(VisitConditionTab.DOCUMENTATION, supportingData?.mentions ?? []),
                this.parseSupportingData(VisitConditionTab.SERVICES, supportingData?.serviceOrders ?? []),
            ];
            this.selectedSection = labsSection;
            this.selectedTab = labsSection.header;
        },
        parseSupportingData<T extends VisitConditionDataSection['items']>(header: VisitConditionTab, items: T): VisitConditionDataSection {
            return { header, items };
        },
        getConditionEnd(condition: VisitCondition) {
            return condition?.lengthOfTreatment?.endDate ? moment(condition.lengthOfTreatment.endDate) : moment();
        },
    },
});
