import { DateSelection, NULLABLE } from '@/components/shared/utils';
import { ClinicalDocumentType } from '@/models';
import { deleteClinicalDocument } from '@/shared/mutations';
import { getClinicalDocumentDraft } from '@/shared/queries';
import { ClinicalDocument, Maybe, Mutation, Query, Visit } from 'generated/graphql/graphql';
import moment from 'moment';
import { defineStore } from 'pinia';

export const useVisitStore = defineStore('visit', {
    state: () => ({
        visit: undefined as Maybe<Visit> | undefined,
        clinicalSummary: undefined as Maybe<ClinicalDocument> | undefined,
        appealLetterSummary: undefined as Maybe<ClinicalDocument> | undefined,
        visitSnapshotId: undefined as Promise<any> | undefined,
        isVisitSummaryAppLoaded: false,
        startDate: undefined as Date | undefined,
        endDate: undefined as Date | undefined,
        dateSelection: undefined as DateSelection | undefined,
        authNumber: undefined as string | null | undefined,
        initialAuthNumber: undefined as string | null | undefined,
        authStartDate: undefined as string | undefined,
        authEndDate: undefined as string | undefined,
        pendingDocumentMutations: [] as Promise<void>[],
        selectedDenialId: undefined as Maybe<number> | undefined,
        appealedDenialId: undefined as Maybe<number> | undefined,
        appealLetterId: undefined as Maybe<string> | undefined,
    }),
    actions: {
        isDateWithinDateFilter(startDate: NULLABLE<string>, endDate?: NULLABLE<string>): boolean {
            if (!endDate && startDate) {
                return moment(startDate).isBetween(this.startDate, this.endDate, 'days', '[]');
            }
            if (startDate && endDate) {
                return (
                    moment(startDate).isBetween(this.startDate, this.endDate, 'days', '[]') ||
                    moment(endDate).isBetween(this.startDate, this.endDate, 'days', '[]')
                );
            }
            return false;
        },
        async deleteDocument(documentType: ClinicalDocumentType): Promise<void> {
            const document: NULLABLE<ClinicalDocument> =
                documentType === ClinicalDocumentType.CLINICAL_SUMMARY ? this.clinicalSummary : this.appealLetterSummary;
            if (!this.visit?.id || !document?.id) {
                return;
            }
            switch (documentType) {
                case ClinicalDocumentType.CLINICAL_SUMMARY:
                case ClinicalDocumentType.APPEAL:
                    await this.apolloClient.mutate<Mutation>({
                        mutation: deleteClinicalDocument,
                        variables: {
                            input: {
                                visitId: +this.visit.id,
                                clinicalDocumentId: +document.id,
                            },
                        },
                    });
                    if (documentType === ClinicalDocumentType.CLINICAL_SUMMARY) {
                        this.clinicalSummary = undefined;
                    } else {
                        this.appealLetterSummary = undefined;
                    }
                    break;
                default:
                    console.error('Unknown document type trying to be deleted');
            }
        },
        async settleAllDocumentMutations() {
            return await Promise.allSettled(this.pendingDocumentMutations);
        },
        handlePendingDocumentMutation(mutation: Promise<any>) {
            this.pendingDocumentMutations.push(mutation);
        },
        async loadClinicalDocumentDraft(documentType: ClinicalDocumentType) {
            if (!this.visit?.id) {
                console.error('No visit loaded yet');
                return;
            }
            switch (documentType) {
                case ClinicalDocumentType.CLINICAL_SUMMARY:
                case ClinicalDocumentType.APPEAL: {
                    const response = await this.apolloClient.query<Query>({
                        query: getClinicalDocumentDraft,
                        variables: {
                            filter: {
                                visitId: +this.visit.id,
                                startDate: this.startDate,
                                endDate: this.endDate,
                                type: documentType,
                            },
                        },
                        fetchPolicy: 'no-cache',
                    });
                    if (documentType === ClinicalDocumentType.CLINICAL_SUMMARY) {
                        this.clinicalSummary = response.data.clinicalDocumentDraft;
                    } else {
                        this.appealLetterSummary = response.data.clinicalDocumentDraft;
                    }
                    return response.data.clinicalDocumentDraft;
                }
                default:
                    console.error('Unknown document type trying to be loaded');
            }
        },
        async getAppealLetterSummary() {
            if (!this.appealLetterSummary) {
                await this.loadClinicalDocumentDraft(ClinicalDocumentType.APPEAL);
            }
            return this.appealLetterSummary;
        },
        getFaxFromId(faxNumberId: number) {
            if (faxNumberId) {
                return this.visit?.primaryPayer?.faxNumbers?.find((fax) => {
                    return faxNumberId === +fax.id;
                });
            }
            return null;
        },
        async getClinicalSummary() {
            if (!this.clinicalSummary) {
                await this.loadClinicalDocumentDraft(ClinicalDocumentType.CLINICAL_SUMMARY);
            }
            return this.clinicalSummary;
        },
    },
});
