import { Role } from '@/auth/role.enum';
import { DrawerType, ReviewOutcome, ReviewType } from '@/models';
import { InteractQuery, InteractQueryTemplate } from '@/models/interact/interact.model';
import { createReviewWithInteractQuery, prepareVisitForInteractResponse, revokeReviewWithInteractQuery } from '@/shared/mutations';
import { initializeInteract } from '@/shared/queries';
import { useInteractQueryStore } from '@/stores/InteractQueryStore';
import { useUserStore } from '@/stores/UserStore';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { useVisitStore } from '@/stores/VisitStore';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { Maybe, Mutation, Query } from '../../generated/graphql/graphql';

const interactReady = 'artifactready';
const queryView = 'query-view';
const inboxQueryView = 'inbox-query-view';
const feedView = 'feed-view';
const inboxView = 'inbox-view';
export class InteractService {
    showQuery: boolean;
    isQueriesView: boolean;
    interactLibrary: undefined | InteractLibrary;

    private sessionToken: undefined | string;
    private queryStateChangedListener: undefined | Maybe<any>;
    private apollo: ApolloClient<NormalizedCacheObject>;

    constructor(apollo: ApolloClient<NormalizedCacheObject>) {
        this.apollo = apollo;
        this.showQuery = this.isQueriesView = false;

        this.onInteractReady = this.onInteractReady.bind(this);
    }

    public async initialize(): Promise<boolean> {
        try {
            const response = await this.apollo.query<Query>({
                query: initializeInteract,
            });

            const baseurl = response?.data?.initialize?.baseUrl;
            const buildNumber = response?.data?.initialize?.buildNumber;
            this.sessionToken = response?.data?.initialize?.sessionToken;

            document.addEventListener(interactReady, this.onInteractReady);
            loadStyle(`${baseurl}/${buildNumber}/app/app.css`);
            loadScript(`${baseurl}/${buildNumber}/app/library.js`);
            return true;
        } catch (err) {
            console.error(`Error initializing Interact.`, err);
            return false;
        }
    }

    public async createInteractQueryForTemplate(name: InteractQueryTemplate): Promise<string | null> {
        await this.setCurrentInteractEncounter();
        try {
            const interactLibrary = await this.getInteractLibrary();
            const queryId: string = await new Promise((resolve) => {
                interactLibrary.createQueryForTemplate(name, (err: any, queryId: string | null) => {
                    if (!queryId) {
                        console.error(`Error opening Interact create query for template popup, received error: ${err}`);
                        return resolve('');
                    }
                    resolve(queryId);
                });
            });
            if (!queryId) {
                console.error(`Error creating Interact query, no queryId returned.`);
                return null;
            }
            await new Promise((resolve, reject) => {
                interactLibrary.setCurrentQuery(queryId, (err) => {
                    if (err) {
                        reject(err);
                        return;
                    }
                    return resolve(true);
                });
            });
            if (!this.isQueriesView) {
                this.openDrawer();
            }
            return queryId;
        } catch (error) {
            console.error(`Error creating Interact query.`, error);
        }
        return null;
    }

    public async setCurrentInteractEncounter(): Promise<void> {
        try {
            const visitStore = useVisitStore();
            const interactLibrary = await this.getInteractLibrary();
            const encounterId: string = await new Promise((resolve, reject) => {
                if (!visitStore.visit?.location?.facility?.code || !visitStore.visit?.encounterNo?.valueOf()) {
                    reject(new Error('Encounter number or visit facility code could not be found in visit store.'));
                    return;
                }
                if (!visitStore.visit?.location?.facility?.interactOrganizationId) {
                    reject(new Error('Interact organizational ID was not found for the given facility'));
                    return;
                }
                interactLibrary.findEncounterByVisitNumber(
                    visitStore.visit?.location?.facility?.interactOrganizationId,
                    visitStore.visit?.location?.facility?.code,
                    visitStore.visit?.encounterNo?.valueOf(),
                    (err, encounterId) => {
                        if (err) {
                            reject(err);
                            return;
                        }
                        return resolve(encounterId);
                    }
                );
            });

            if (encounterId) {
                return await new Promise((resolve, reject) => {
                    interactLibrary.setCurrentEncounter(encounterId, (err) => {
                        if (err) {
                            reject(err);
                            return;
                        }
                        return resolve();
                    });
                });
            }
        } catch (error) {
            console.error(`Error setting Interact current encounter.`, error);
        }
    }

    public async openQueryEditor(): Promise<void> {
        const queryEditor = document.getElementById(queryView);
        (await this.getInteractLibrary()).createQueryEditorComponent(queryEditor);
        this.showQuery = true;
    }

    public async openInboxQueryEditor(): Promise<void> {
        (await this.getInteractLibrary()).createInboxQueryComponent(inboxQueryView);
        this.showQuery = true;
    }

    public async loadEncounterView(): Promise<void> {
        if (this.isAuthorMode()) {
            return this.loadFeed();
        }

        this.loadInbox();
    }

    public async loadFeed(): Promise<void> {
        (await this.getInteractLibrary()).createFeedComponent(feedView, { showCreateButton: false });
        this.isQueriesView = true;
    }

    public async loadInbox(): Promise<void> {
        (await this.getInteractLibrary()).createInboxComponent(inboxView);
        this.isQueriesView = true;
    }

    public setIsQueriesView(isQueriesView: boolean): void {
        this.isQueriesView = isQueriesView;

        if (!isQueriesView) {
            this.showQuery = false;
        }
    }

    public async attachLibraryEvents(): Promise<void> {
        if (!this.queryStateChangedListener) {
            const interactLibrary = await this.getInteractLibrary();
            this.queryStateChangedListener = interactLibrary.queryStateChanged?.subscribe((args: any) =>
                setTimeout(() => {
                    this.updateStoreWithLatestQueryChange(args.queryChangeData);
                    if (this.isAuthorMode()) {
                        if (!this.isQueriesView) {
                            this.openDrawer();
                        }
                        setTimeout(() => {
                            this.showQueryEditor(args.opened);
                            this.handleActiveQueryChange(args);
                        });
                    } else if (args.opened) {
                        // opens query in PA workflow
                        this.openInboxQueryEditor();
                    } else {
                        // PA has responded to the query
                        this.showQueryEditor(args.opened);
                        this.prepareVisitForInteractResponse();
                    }
                })
            );
        }
    }

    public updateStoreWithLatestQueryChange(queryChangeData: any): void {
        if (!queryChangeData?.query) {
            return;
        }
        const interactQueryStore = useInteractQueryStore();
        const updatedInteractQuery: InteractQuery = {
            id: queryChangeData.query.id ?? null,
            encounter: queryChangeData.query.encounter.identifier ?? null,
            status: queryChangeData.query.status.value ?? null,
            template: queryChangeData.query.template ?? null,
            createdDate: queryChangeData.query.current.createdDate ?? null,
            sentDate: queryChangeData.query.current.sentDate ?? null,
            kind: queryChangeData.query.current.kind.value ?? null,
        };
        interactQueryStore.updateMostRecentQuery(updatedInteractQuery);
    }

    public async getInteractLibrary(): Promise<InteractLibrary> {
        if (this.interactLibrary) {
            return this.interactLibrary;
        }

        return new Promise((resolve, reject) => {
            const maxIterations = 100;
            let iterations = 0;
            const interval = setInterval(() => {
                iterations = iterations + 1;
                if (this.interactLibrary) {
                    clearInterval(interval);
                    resolve(this.interactLibrary);
                } else if (iterations >= maxIterations) {
                    clearInterval(interval);
                    reject('Unable to get interact library after 10 seconds');
                }
            }, 100);
        });
    }

    public isAuthorMode() {
        const userStore = useUserStore();
        return userStore.currentRole != Role.PHYSICIAN_ADVISOR;
    }

    public async handleActiveQueryChange(args: any): Promise<boolean | null> {
        const visitStore = useVisitStore();
        if (!visitStore?.visit?.externalModelId || args.active === undefined || args.queryId === undefined) {
            return null;
        }
        const queryId = args.queryId;
        const active = args.active;
        const variables = {
            input: {
                queryId,
                visitId: Number(visitStore?.visit?.id),
                primaryRecipientName: args.queryChangeData.query.current.recipients[0].name ?? null,
            },
        };
        if (active) {
            const result = await this.apollo.mutate<Mutation>({
                mutation: createReviewWithInteractQuery,
                variables,
            });
            visitStore.$patch({
                visit: { ...visitStore.visit, lastReviewOutcome: ReviewOutcome.ESCALATE, lastReviewType: ReviewType.FIRST_LEVEL },
            });
            return !!result;
        }
        if (active === false) {
            const result = this.apollo.mutate<Mutation>({
                mutation: revokeReviewWithInteractQuery,
                variables,
            });
            visitStore.$patch({
                visit: { ...visitStore.visit, lastReviewOutcome: undefined, lastReviewType: ReviewType.FIRST_LEVEL },
            });
            return !!result;
        }
        return null;
    }

    public onInteractReady = () => {
        this.setInteractLibrary();
        // we don't use the async getInteractLibrary call here because we know it will be set in the line above
        this.interactLibrary?.initialize(this.sessionToken, (err) => {
            if (err) {
                console.error(`Error initializing Interact in onInteractReady.`, err);
                return;
            }
            this.attachLibraryEvents();
            return;
        });
    };

    public setInteractLibrary(): void {
        this.interactLibrary = artifact;
    }

    private openDrawer(): void {
        this.showQuery = true;
        const visitDrawerStore = useVisitDrawerStore();
        visitDrawerStore.openDrawer({
            drawer: DrawerType.INTERACT_QUERY,
            drawerProps: { maxWidth: true },
        });
    }

    private showQueryEditor(show: boolean): void {
        if (show) {
            this.openQueryEditor();
            this.showQuery = true;
        } else {
            const visitDrawerStore = useVisitDrawerStore();
            if (visitDrawerStore.drawer) {
                visitDrawerStore.closeDrawer();
            }
            this.showQuery = false;
        }
    }

    public prepareVisitForInteractResponse(): void {
        const visitStore = useVisitStore();

        if (visitStore?.visit?.id) {
            this.apollo.mutate<Mutation>({
                mutation: prepareVisitForInteractResponse,
                variables: {
                    visitId: +visitStore?.visit?.id,
                },
            });
        }
    }
}

export function loadStyle(url: string): void {
    const style = document.createElement('link');
    style.rel = 'stylesheet';
    style.href = url;
    document.head.appendChild(style);
}

export function loadScript(url: string): void {
    const script = document.createElement('script');
    script.async = true;
    script.src = url;
    document.head.appendChild(script);
}
